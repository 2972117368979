<script setup lang="ts">
const isMenuOpen = ref(false)

const toggleMenu = () => {
	isMenuOpen.value = !isMenuOpen.value
}
</script>

<template>
	<div class="absolute inset-0 h-screen w-screen p-12">
		<div class="flex flex-row gap-4 justify-between">
			<div class="flex flex-col gap-2">
				<slot />
			</div>
			<div class="flex flex-row gap-4">
				<SiteLanguageSwitch />
				<div class="flex flex-col gap-4 items-end">
					<Icon name="simple-line-icons:menu" class="text-3xl" @click="toggleMenu" />

					<a href="https://www.instagram.com/joke_m_vink/" target="_blank">
						<Icon name="lineicons:instagram-original" class="text-3xl" />
					</a>
				</div>
			</div>
		</div>
		<nav
			v-if="isMenuOpen"
			@click="toggleMenu"
			class="absolute inset-0 h-screen w-screen bg-black/50 text-white flex justify-center items-center text-3xl"
		>
			<ul class="flex flex-col gap-4">
				<li>
					<NuxtLinkLocale to="index">{{ $t('pages.home.title') }}</NuxtLinkLocale>
				</li>
				<li>
					<NuxtLinkLocale to="about">{{ $t('pages.about.title') }}</NuxtLinkLocale>
				</li>
				<li>
					<NuxtLinkLocale to="contact">{{ $t('pages.contact.title') }}</NuxtLinkLocale>
				</li>
			</ul>
		</nav>
	</div>
</template>
