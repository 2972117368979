<script setup lang="ts">
const switchLocalePath = useSwitchLocalePath()
const { locale } = useI18n()
</script>

<template>
	<div class="flex flex-col gap-1">
		<div class="flex gap-1">
			<NuxtLink
				role="button"
				:to="switchLocalePath('en')"
				:class="{ 'text-blue-700 dark:text-yellow-400': locale !== 'en' }"
				>EN</NuxtLink
			>/
			<NuxtLink
				role="button"
				:to="switchLocalePath('nl')"
				:class="{ 'text-blue-700 dark:text-yellow-400': locale !== 'nl' }"
				>NL</NuxtLink
			>
		</div>
	</div>
</template>
